import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import HomeStyles from '../components/home-page-styles.module.less'

import Layout from '../components/layout'
import GraduatesBanner from '../components/graduates-banner'
import TwitterIcon from '../../static/assets/icons/twitter-icon.svg'
import InstagramIcon from '../../static/assets/icons/instagram-icon.svg'
import FacebookIcon from '../../static/assets/icons/facebook-icon.svg'
// import ShuffleIcon from '../../static/assets/icons/refresh-icon.svg'

import HeaderTextData from '../content/data/home-page-header-phrases.json'
import StickerTextData from '../content/data/home-page-sticker-text.json'

const brandColours = ['#004AD1', '#FFBA01', '#F85848', '#FF8000', '#00DCCC']

export default class Index extends Component {
	constructor(props) {
		super(props)
		this.state = {
			width: 0,
			height: 0,
			headerVisible: true,
			headerText:
				HeaderTextData[
					Math.floor(
						Math.random() * Object.keys(HeaderTextData).length,
					)
				],
			headerBackgroundColour:
				brandColours[
					Math.floor(Math.random() * Object.keys(brandColours).length)
				],
			currentButtonSpinAmount: 0,
		}

		// this.stickerSVG = React.createRef()

		this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
		this.handleHeaderText = this.handleHeaderText.bind(this)
	}

	componentDidMount() {
		this.updateWindowDimensions()

		window.addEventListener('resize', this.updateWindowDimensions)
		// window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions)
		// window.removeEventListener('scroll', this.handleScroll)
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight })
	}

	handleHeaderText() {
		const currentSpin = this.state.currentButtonSpinAmount
		this.setState({
			currentButtonSpinAmount: currentSpin + 360,
		})

		const dataLength = Object.keys(HeaderTextData).length
		let randomNumber = Math.floor(Math.random() * dataLength)
		if (HeaderTextData[randomNumber] === this.state.headerText) {
			if (randomNumber === dataLength - 1) {
				randomNumber = 0
			} else {
				randomNumber++
			}
		}

		let randomColourNumber = Math.floor(Math.random() * brandColours.length)

		if (
			brandColours[randomColourNumber] ===
			this.state.headerBackgroundColour
		) {
			if (randomColourNumber === brandColours.length - 1) {
				randomColourNumber = 0
			} else {
				randomColourNumber++
			}
		}

		let randomColour = brandColours[randomColourNumber]

		setTimeout(
			function() {
				this.setState({
					headerVisible: false,
					headerBackgroundColour: this.state.headerBackgroundColour,
				})
			}.bind(this),
			300,
		)
		setTimeout(
			function() {
				this.setState({
					headerText: HeaderTextData[randomNumber],
					headerVisible: true,
					headerBackgroundColour: randomColour,
				})
			}.bind(this),
			600,
		)
	}

	handleSpin() {
		const currentSpin = this.state.currentButtonSpinAmount
		this.setState({
			currentButtonSpinAmount: currentSpin + 360,
		})
	}

	// handleScroll() {
	// 	const scrollTop = this.stickerSVG.current.getBoundingClientRect().top,
	// 		itemTranslate =
	// 			scrollTop * -1 > 1500
	// 				? 1500
	// 				: scrollTop * -1 > 0
	// 					? scrollTop * -1
	// 					: 0
	//
	// 	this.setState({
	// 		stickerOffset: itemTranslate,
	// 	})
	// }

	render() {
		return (
			<main className={HomeStyles.wrapper}>
				<header
					className={HomeStyles.desktopHeader}
					style={
						this.state.width > 768
							? {
								background: `${
									this.state.headerBackgroundColour
								}`,
							  }
							: { display: 'none' }
					}
				>
					<section className={HomeStyles.desktopHeaderGrid}>
						<nav className={HomeStyles.desktopNav}>
							<svg
								className={HomeStyles.desktopNavLogo}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 60 40"
								width="60"
								height="40"
							>
								<path
									style={{
										fillRule: 'evenodd',
										clipRule: 'evenodd',
										fill: 'white',
									}}
									d="M30.1,17.6c0.2,0.8,0,1.5-0.4,1.9c-0.4,0.4-1.1,0.7-2.2,0.9l-2,0.4L24.7,16l2-0.3c1.1-0.2,1.9-0.1,2.4,0.2
								C29.6,16.2,30,16.8,30.1,17.6z M57.3,31.4L7.5,39.3L2.7,8.6l49.8-7.9L57.3,31.4z M20,29.6l-2.4-15.3l-3.7,0.6l2.4,15.3L20,29.6z
								 M35.8,27.2l-4-5.2c0.9-0.5,1.5-1.2,1.9-2c0.4-0.8,0.5-1.8,0.3-3c-0.3-1.8-1.1-2.9-2.3-3.6c-1.2-0.6-3-0.8-5.2-0.4l-5.7,0.9
								l2.4,15.3l3.6-0.6L26,23.5l1.7-0.3c0.3-0.1,0.6-0.1,0.8-0.2l3.4,4.8L35.8,27.2z M48.6,25.1l-0.5-2.9l-6.8,1l-1.9-12.4l-3.7,0.6
								l2.5,15.3L48.6,25.1z"
								/>
							</svg>
							<section
								className={
									HomeStyles.desktopNavSocialMediaContainer
								}
							>
								<a
									className={HomeStyles.desktopNavLink}
									href="https://www.twitter.com/ysdn2019"
									target="_blank"
									rel="noreferrer noopener"
								>
									<img
										className={HomeStyles.desktopNavIcon}
										src={TwitterIcon}
										alt="Twitter Icon"
									/>
								</a>
								<a
									className={HomeStyles.desktopNavLink}
									href="https://www.instagram.com/ysdn2019"
									target="_blank"
									rel="noreferrer noopener"
								>
									<img
										className={HomeStyles.desktopNavIcon}
										src={InstagramIcon}
										alt="Instagram Icon"
									/>
								</a>
								<a
									className={HomeStyles.desktopNavLink}
									href="https://www.facebook.com/events/2218033058447423/"
									target="_blank"
									rel="noreferrer noopener"
								>
									<img
										className={HomeStyles.desktopNavIcon}
										src={FacebookIcon}
										alt="Facebook Icon"
									/>
								</a>
							</section>
						</nav>
						<h1
							className={HomeStyles.desktopHeaderOne}
							style={
								this.state.headerVisible === true
									? { opacity: '1' }
									: { opacity: '0' }
							}
						>
							{this.state.headerText.before}
							<span style={{ textDecoration: 'underline' }}>
								{this.state.headerText.underlined}
							</span>
							{this.state.headerText.after}
						</h1>
						<article className={HomeStyles.desktopHeaderTwoArticle}>
							<h2 className={HomeStyles.desktopHeaderTwo}>
								This is design,{' '}
								<span
									className={HomeStyles.desktopHeaderTwoSpan}
								>
									In Real Life
								</span>
							</h2>
							<button className={HomeStyles.desktopHeaderButton}>
								<div
									className={
										HomeStyles.desktopHeaderButtonImage
									}
									// onClick={e => this.handleSpin(e)}
									// style={{
									// 	transform: `rotate(${
									// 		this.state.currentButtonSpinAmount
									// 	}deg)`,
									// }}
								>
									{
										// <img
										// 	className={
										// 		HomeStyles.desktopHeaderButtonImage
										// 	}
										// 	src={ShuffleIcon}
										// 	alt="Shuffle Text Icon"
										// 	onClick={this.handleHeaderText}
										// />
									}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 101.01 101.01"
										width="44"
										height="44"
										onClick={this.handleHeaderText}
										transform={`rotate(${
											this.state.currentButtonSpinAmount
										})`}
										className={
											HomeStyles.desktopHeaderButtonSpinner
										}
									>
										<circle
											style={{
												fill: 'none',
												stroke: '#FFFFFF',
												strokeWidth: '4',
												strokeMiterlimit: '10',
											}}
											fill="none"
											stroke="#FFFFFF"
											strokeWidth="4"
											strokeMiterlimit="10"
											cx="50.51"
											cy="50.51"
											r="48.51"
										/>
										<g id="Top_Arrow">
											<path
												style={{
													fill: 'none',
													stroke: '#FFFFFF',
													strokeWidth: '4',
													strokeLinecap: 'round',
													strokeLinejoin: 'round',
												}}
												d="M33.27,35.88
									  		c8.21-9.54,22.61-10.62,32.15-2.41c5.04,4.33,7.93,10.65,7.93,17.3"
											/>
											<polyline
												style={{
													fill: 'none',
													stroke: '#FFFFFF',
													strokeWidth: '4',
													strokeLinecap: 'round',
													strokeLinejoin: 'round',
												}}
												points="80.72,43.98
									  		73.5,53.25 64.37,46.14 	"
											/>
										</g>
										<g id="Bottom_Arrow">
											<polyline
												style={{
													fill: 'none',
													stroke: '#FFFFFF',
													strokeWidth: '4',
													strokeLinecap: 'round',
													strokeLinejoin: 'round',
												}}
												points="20.33,57.01
									  		27.56,47.74 36.68,54.85 	"
											/>
											<path
												style={{
													fill: 'none',
													stroke: '#FFFFFF',
													strokeWidth: '4',
													strokeLinecap: 'round',
													strokeLinejoin: 'round',
												}}
												d="M67.79,65.11
									  		c-8.22,9.54-22.63,10.61-32.17,2.39c-5.03-4.33-7.92-10.64-7.92-17.28"
											/>
										</g>
									</svg>
								</div>
							</button>
						</article>
						<article className={HomeStyles.desktopHeaderParagraphs}>
							<p className={HomeStyles.desktopHeaderParagraph}>
								The 2018–19 York University/Sheridan College
								Grad Show
							</p>
							<p
								style={{ paddingRight: '10px' }}
								className={HomeStyles.desktopHeaderParagraph}
							>
								April 10th & 11th at Free space
							</p>
						</article>
					</section>
				</header>
				<Layout
					onHomepage={true}
					backgroundColour={this.state.headerBackgroundColour}
				>
					<header
						className={HomeStyles.mobileHeader}
						style={
							this.state.width <= 768
								? {
									background: `${
										this.state.headerBackgroundColour
									}`,
								  }
								: { display: 'none' }
						}
					>
						<div>
							<h1
								className={HomeStyles.mobileHeaderOne}
								style={
									this.state.headerVisible === true
										? { opacity: '1' }
										: { opacity: '0' }
								}
							>
								{this.state.headerText.before}
								<span style={{ textDecoration: 'underline' }}>
									{this.state.headerText.underlined}
								</span>
								{this.state.headerText.after}
							</h1>
							<h2 className={HomeStyles.mobileHeaderTwo}>
								This is design,{' '}
								<span className={HomeStyles.mobileHeaderSpan}>
									In Real Life
								</span>
							</h2>
							<button
								className={HomeStyles.mobileHeaderShuffleButton}
							>
								<div
									className={
										HomeStyles.mobileHeaderShuffleImage
									}
									onClick={e => this.handleSpin(e)}
									// style={{
									// 	transform: `rotate(${
									// 		this.state.currentButtonSpinAmount
									// 	}deg)`,
									// }}
								>
									{
										// <img
										// 	className={
										// 		HomeStyles.mobileHeaderShuffleImage
										// 	}
										// 	src={ShuffleIcon}
										// 	alt="Shuffle Icon"
										// 	onClick={this.handleHeaderText}
										// />
									}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 101.01 101.01"
										width="44"
										height="44"
										onClick={this.handleHeaderText}
										transform={`rotate(${
											this.state.currentButtonSpinAmount
										})`}
										className={
											HomeStyles.mobileHeaderButtonSpinner
										}
									>
										<circle
											style={{
												fill: 'none',
												stroke: '#FFFFFF',
												strokeWidth: '4',
												strokeMiterlimit: '10',
											}}
											fill="none"
											stroke="#FFFFFF"
											strokeWidth="4"
											strokeMiterlimit="10"
											cx="50.51"
											cy="50.51"
											r="48.51"
										/>
										<g id="Top_Arrow">
											<path
												style={{
													fill: 'none',
													stroke: '#FFFFFF',
													strokeWidth: '4',
													strokeLinecap: 'round',
													strokeLinejoin: 'round',
												}}
												d="M33.27,35.88
									  		c8.21-9.54,22.61-10.62,32.15-2.41c5.04,4.33,7.93,10.65,7.93,17.3"
											/>
											<polyline
												style={{
													fill: 'none',
													stroke: '#FFFFFF',
													strokeWidth: '4',
													strokeLinecap: 'round',
													strokeLinejoin: 'round',
												}}
												points="80.72,43.98
									  		73.5,53.25 64.37,46.14 	"
											/>
										</g>
										<g id="Bottom_Arrow">
											<polyline
												style={{
													fill: 'none',
													stroke: '#FFFFFF',
													strokeWidth: '4',
													strokeLinecap: 'round',
													strokeLinejoin: 'round',
												}}
												points="20.33,57.01
									  		27.56,47.74 36.68,54.85 	"
											/>
											<path
												style={{
													fill: 'none',
													stroke: '#FFFFFF',
													strokeWidth: '4',
													strokeLinecap: 'round',
													strokeLinejoin: 'round',
												}}
												d="M67.79,65.11
									  		c-8.22,9.54-22.63,10.61-32.17,2.39c-5.03-4.33-7.92-10.64-7.92-17.28"
											/>
										</g>
									</svg>
								</div>
							</button>
						</div>
						<div>
							<p className={HomeStyles.mobileHeaderParagraph}>
								The 2018-19 York University/
								<br />
								Sheridan College Grad Show
							</p>
							<p className={HomeStyles.mobileHeaderParagraph}>
								April 10th & 11th at Free space
							</p>
						</div>
					</header>
					<main className={HomeStyles.wrapper}>
						<section
							className={HomeStyles.grid}
							ref={this.stickerSVG}
						>
							<svg
								width="1540"
								height="1080"
								viewBox="0 0 1440 1080"
								xmlns="http://www.w3.org/2000/svg"
								className={HomeStyles.stickersSvg}
								style={{
									transform: `translateY(0) translateX(${
										this.state.width > 1200
											? -192
											: Math.min(
												(this.state.width - 1540) /
														2,
												-192,
											  )
									}px)`,
								}}
							>
								{this.state.width >= 768
									? Object.keys(StickerTextData).map(
										(key, index) => (
											<g
												key={index}
												transform={`translate(${Math.floor(
													(Math.random() > 0.5
														? Math.random() /
																	5 +
															  0.7
														: Math.random() /
																	5 -
															  0.12) *
															(1440 * 0.9) +
															1440 * 0.1,
												)} ${Math.floor(
													Math.random() *
															(1080 - 180) +
															90,
												)}) rotate(${Math.floor(
													Math.random() * 30 - 15,
												)})`}
											>
												<rect
													x="0"
													y="0"
													width={
														StickerTextData[key]
															.length *
																9 +
															50
													}
													height="37"
													fill={
														brandColours[
															Math.floor(
																Math.random() *
																		brandColours.length,
															)
														]
													}
												/>
												<text
													x="0"
													dx={
														StickerTextData[key]
															.length *
																5.25 +
															15
													}
													y="0"
													dy="24"
													textAnchor="middle"
													style={{
														fontSize: '18px',
														fontWeight: 'bold',
														fill: 'white',
													}}
												>
													{
														// 12345678901234567890
													}
													{StickerTextData[key]}
												</text>
											</g>
										),
									  )
									: 'nothing'}
							</svg>
							<article className={HomeStyles.gradShowDescription}>
								<h3
									className={
										HomeStyles.gradShowDescriptionHeader
									}
								>
									This isn’t your average design show.
								</h3>
								<div>
									<p
										className={
											HomeStyles.gradShowDescriptionParagraph
										}
									>
										This is a celebration of conquering
										critiques, surviving sleepless nights,
										earning our degrees, and making some
										cool stuff along the way. We’ve decided
										that after four years of closed doors,
										we are ready to let you in on who we
										are!
									</p>
									<p
										className={
											HomeStyles.gradShowDescriptionParagraph
										}
									>
										Get ready to meet a Taco Indulger, a Pig
										Enthusiast, a Selfie Master, an
										Empathetic Thinker, a Meme Queen, a
										Night Owl, and many, many, Ambitious
										Designers. We run on ramen. We crave
										cold-brew. We are fuelled by tears. We
										are designers, and we are people. Come
										meet us, all in Real Life!
									</p>
								</div>
							</article>
							<article className={HomeStyles.callout}>
								<h3 className={HomeStyles.calloutHeader}>
									Come meet the designers behind the design.
									Come meet us, IRL
								</h3>
							</article>
							<article className={HomeStyles.meetGraduates}>
								<GraduatesBanner />
							</article>
							<div
								className={HomeStyles.exploreTheWorkBackground}
							/>
							<article className={HomeStyles.exploreTheWork}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 264 293"
									className={HomeStyles.exploreTheWorkSvg}
								>
									<mask id="theMask">
										<rect
											x="0"
											y="0"
											width="264"
											height="293"
											fill="black"
										/>
										<polygon
											points="134.6,86.8 257.3,78.2 264.3,179.4 170.9,185.9 209.1,192.7 191.6,292.6 0,258.7 17.6,158.9
										49.2,164.5 45.2,106.3 3.3,100.4 17.4,0 144.3,17.9 "
											fill="white"
										/>
									</mask>
									<image
										x="-50%"
										y="-50%"
										width="200%"
										height="200%"
										xlinkHref={
											this.props.data.allMarkdownRemark
												.edges[
													Math.floor(
														Math.random() *
														this.props.data
															.allMarkdownRemark
															.edges.length,
													)
												].node.frontmatter.cover
												.childImageSharp.fluid.src
										}
										alt="Student Work behind the IRL Logo Mask"
										mask="url(#theMask)"
									/>
								</svg>
								<div>
									<h3
										className={
											HomeStyles.exploreTheWorkHeader
										}
									>
										Put your eyes together for the class of
										2019
									</h3>
									<p
										className={
											HomeStyles.exploreTheWorkParagraph
										}
									>
										Without Drake or Rihanna, we can still
										show you all the work, work, work, work,
										work, work. Check it out!
									</p>
									<Link
										className={
											HomeStyles.exploreTheWorkLink
										}
										to="/work/"
									>
										Explore the Work
									</Link>
								</div>
							</article>
							<div
								className={HomeStyles.comeMeetTheCastBackground}
							/>
							<div
								className={
									HomeStyles.comeMeetTheCastBackgroundBottom
								}
							/>
							<article className={HomeStyles.comeMeetTheCast}>
								<h3
									className={HomeStyles.comeMeetTheCastHeader}
								>
									Like What You See?
								</h3>
								<p
									className={
										HomeStyles.comeMeetTheCastParagraph
									}
								>
									Meet the grads behind these projects In Real
									Life at the grad show on April 10 & 11 at
									Free Space!
								</p>
								<Link
									className={HomeStyles.comeMeetTheCastLink}
									to="/about/"
								>
									View Details
								</Link>
							</article>
						</section>
					</main>
				</Layout>
			</main>
		)
	}
}

Index.propTypes = {
	data: PropTypes.any,
}

export const query = graphql`
	query {
		allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/work/" } }) {
			edges {
				node {
					frontmatter {
						cover {
							childImageSharp {
								fluid(maxWidth: 960) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`
